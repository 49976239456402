import React, { Fragment } from "react"
import { graphql, Link } from "gatsby"
import { GatsbyImage, StaticImage } from "gatsby-plugin-image"
import Seo from "gatsby-plugin-wpgraphql-seo"

import Layout from "../components/layout"
import Sidebar from "../components/sidebar"
import HeaderSimple from "../components/blocks/HeaderSimple";

import "../styles/posts-list.scss"

export default function Archive({ data, pageContext, location }) {
  const heading = data?.wpPage?.blocks?.layouts
  const posts = data?.allWpPost?.nodes
  const currentPage = (pageContext.offset / 5) + 1

  const getVisiblePages = (totalPages, currentPage) => {
    const pages = [];

    if (totalPages <= 7) {
      return [...Array(totalPages)].map((_, i) => i);
    }

    // Always add first page
    pages.push(0);

    if (currentPage <= 4) {
      // Show first 5 pages
      for (let i = 1; i < 5; i++) {
        pages.push(i);
      }
      pages.push(null); // ellipsis
      pages.push(totalPages - 1);
    } else if (currentPage >= totalPages - 3) {
      // Show last 5 pages
      pages.push(null); // ellipsis
      for (let i = totalPages - 5; i < totalPages; i++) {
        pages.push(i);
      }
    } else {
      // Show current page and neighbors
      pages.push(null); // ellipsis
      pages.push(currentPage - 2);
      pages.push(currentPage - 1);
      pages.push(currentPage);
      pages.push(null); // ellipsis
      pages.push(totalPages - 1);
    }

    return pages;
  };

  return (
    <Fragment>
      <Seo
        title="News"
        // postSchema={JSON.parse(
        //   data.wp?.seo?.contentTypes?.post.schema.raw
        // )}
      />
      <Layout location={location}>
        {heading && (
          <HeaderSimple data={heading[0]} />
        )}

        <div className="row news-row">
          <div className="search-bar">
            <input type="search" placeholder="search news..." />
            <button>
              <StaticImage src="../images/search-icon.png" />
            </button>
          </div>

          <div className="posts-list-wrapper">
            {posts?.map(post => {
              return (
                <article className="post">
                  <div className="row">
                    <div className="col featured-image">
                      <Link to={post?.uri}>
                        <GatsbyImage image={post?.featuredImage?.node?.localFile?.childImageSharp?.gatsbyImageData} alt={post?.altText} />
                      </Link>
                    </div>
                    <div className="col post-content">
                      <h2 className="title"><Link to={post?.uri}>{post?.title}</Link></h2>
                      <div className="date">{post?.date}</div>
                      <div className="description" dangerouslySetInnerHTML={{ __html: post?.excerpt}}></div>
                    </div>
                  </div>
                </article>
              )
            })}
            <div className="pagination">
              <Link
                to={pageContext?.previousPagePath}
                className={"previous " + (currentPage === 1 ? 'inactive' : '')}
              />
              {getVisiblePages(pageContext?.totalPages, currentPage - 1).map((page, index) => {
                if (page === null) {
                  return <span key={`ellipsis-${index}`}>...</span>;
                }
                const link = page ? `/news/${page + 1}` : '/news/';
                return (
                  <Link
                    key={page}
                    to={link}
                    className={currentPage === page + 1 ? 'active' : ''}
                  >
                    {page + 1}
                  </Link>
                );
              })}
              <Link
                to={pageContext?.nextPagePath}
                className={"next " + (currentPage === pageContext.totalPages ? 'inactive' : '')}
              />
            </div>
          </div>
          <div className="sidebar-wrapper">
            <Sidebar />
          </div>
        </div>
      </Layout>
    </Fragment>
  )
}

export const query = graphql`
  query($offset: Int!, $postsPerPage: Int!) {
    wpPage(slug: { eq: "news" }) {
      blocks {
        layouts {
          ... on WpPage_Blocks_Layouts_HeaderSimple {
            fieldGroupName
            title
            background {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
            mobileBackground {
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
        }
      }
    }
    wp {
      seo {
        contentTypes {
          post {
            schema {
              raw
            }
          }
        }
      }
    }
    allWpPost(
      sort: { fields: [date], order: DESC }
      limit: $postsPerPage
      skip: $offset
    ) {
      nodes {
        uri
        date(formatString: "MMMM DD, YYYY")
        title
        excerpt
        featuredImage {
          node {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
  }
`

// export async function config() {
//   return ({ params }) => {
//     return {
//       defer: true,
//     }
//   }
// }
